const redirectToDomain = (path, response, domain) => {
  const target = `${domain}${path}`;

  if (response) {
    if (!response.finished) {
      response.writeHead(301, { Location: target });
      response.end();
    }
  } else {
    window.location.replace(target);
  }
};

export default redirectToDomain;
