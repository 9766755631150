import React from 'react';
import PropTypes from 'prop-types';
import initApolloClient from './initApollo';
import getAppProperties from './getAppProperties';
import { getMessages } from './i18n.mjs';

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 */
export default function withApollo(PageComponent) {
  const WithApollo = (properties) => <PageComponent {...properties} />;

  WithApollo.getLayout = PageComponent.getLayout;
  WithApollo.getInitialProps = async (context) => {
    const { AppTree, req: request, res: response } = context;

    // Initialize ApolloClient, add it to the ctx object so
    // we can use it in `PageComponent.getInitialProp`.
    context.apolloClient = initApolloClient(null, { req: request });
    const { apolloClient } = context;

    // Run wrapped getInitialProps methods
    let pageProperties = {};
    if (PageComponent.getInitialProps) {
      pageProperties = await PageComponent.getInitialProps(context);
    }

    // Only on the server:
    if (!process.browser) {
      // When redirecting, the response is finished.
      // No point in continuing to render
      if (response && response.finished) {
        return pageProperties;
      }

      try {
        // Run all GraphQL queries
        const { getDataFromTree } = await import('@apollo/client/react/ssr');
        await getDataFromTree(
          <AppTree
            {...getAppProperties(context)}
            pageProps={{
              ...pageProperties,
              apolloClient,
            }}
            messages={getMessages(request.locale)}
          />,
        );
      } catch (error) {
        // Prevent Apollo Client GraphQL errors from crashing SSR.
        // Handle them in components via the data.error prop:
        // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
        // eslint-disable-next-line no-console
        console.error(`Error while running \`getDataFromTree\` for ${
          request.hostname}${
          request.url}`, error);
      }
    }

    // Extract query data from the Apollo store
    const apolloState = apolloClient.cache.extract();

    // don't serialize apolloClient for the browser
    apolloClient.toJSON = () => null;

    return {
      ...pageProperties,
      apolloState,
      apolloClient,
    };
  };

  WithApollo.propTypes = {
    apolloClient: PropTypes.shape({}),
    apolloState: PropTypes.shape({}),
  };

  WithApollo.defaultProps = {
    apolloClient: null,
    apolloState: null,
  };

  return WithApollo;
}
