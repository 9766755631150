import { NextPageContext } from 'next';
import redirectToDomain from './redirectToDomain';
import getUrlFromRequest from './getUrlFromRequest.mjs';
import getPrefix from './getPrefix.mjs';
import hasNews from './hasNews';
import getWindowUrl from './getWindowUrl.mjs';
import { getTargetOrigin } from './redirectRouteToDomain';

const redirectToDomainByPrefix: Record<string, string> = {
  'fashionunited.ch/lavorare-nella-moda': 'fashionunited.ch',
  'fashionunited.ch/fashion-jobs': 'fashionunited.ch',
};

const redirectToNewsDomain = (path: string) => ({
  req, res,
}: {
  req?: NextPageContext['req'];
  res?: NextPageContext['res'];
}) => {
  const url = req ? getUrlFromRequest(req) : getWindowUrl();
  const prefix = getPrefix(url);

  if (!hasNews(prefix)) {
    redirectToDomain(
      path,
      res,
      getTargetOrigin(
        url,
        redirectToDomainByPrefix[prefix] || 'fashionunited.com',
      ),
    );
  }

  return {};
};

export default redirectToNewsDomain;
