import React from 'react';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import withApollo from '../lib/apollo';
import loading from '../lib/loading';
import redirectToNewsDomain from '../lib/redirectToNewsDomain';

const Index = dynamic(() => import('../components/Index'), {
  ssr: true,
  loading,
});

const IndexPage: NextPage = () => (<Index />);

IndexPage.getInitialProps = redirectToNewsDomain('/');

export default withApollo(IndexPage);
